import * as React from "react"
import { graphql } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Helmut from "../components/helmut"

const IndexPage = ({ data, location }) => {

  return (
    <>
      <Helmut title="Powerlifting Stream" />
      <p>Powerlifting Streamed Live</p>
      
      <StaticImage
        src="../images/IMG_0030.JPG"
        alt="Photo of PowerWOD team."
        layout="fullWidth"
        placeholder="blurred"
        
      />

</>    
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
